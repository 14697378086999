<template>
  <dash-page-new
      :title="$t('TechSupport')"
      :subtitle="$t('New')"
      :root="$t('Dashboard')"
      icon="mdi-face-agent"
      :side-navigation="navigationItems"
      navigation-route="support"
  >

    <router-view :selected-status="selectedStatus"></router-view>

    <template #header_action>
      <portal-target name="admin_support_action"></portal-target>
    </template>

  </dash-page-new>
</template>

<script>
export default {
  name: "AdminSupport",
  data() {
    return {
      selectedStatus : 0
    }
  },
  computed : {
    requestStatusSelect() {
      return [
        { text : this.$t('New') + (this.$store.state.support.requestsCounter.new ? ` (${this.$store.state.support.requestsCounter.new})` : '' ), value : 0 },
        { text : this.$t('InProcess') + (this.$store.state.support.requestsCounter.inProcess  ? ` (${this.$store.state.support.requestsCounter.inProcess})` : '') , value : 1 },
        { text : this.$t('Completed') + (this.$store.state.support.requestsCounter.finished  ? ` (${this.$store.state.support.requestsCounter.finished})` : '') , value : 2 },
      ]
    },
    navigationItems() {
      return [
        { text : 'Всі запити'           , path : ''           },
        { text : 'Мої запити'           , path : 'manager'    },
        { text : 'Оновлення'            , path : 'updates'    },
        { text : 'Зараз в роботі'       , path : 'in_work'    },
        { text : 'Часті запитання'      , path : 'faq'    },
        { text : 'Статистика'           , path : 'stats'      },
      ]
    },

  }
}
</script>

<style scoped>

</style>